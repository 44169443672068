<template>
  <a-modal width="520px" :visible="visible" @ok="handleOk" @cancel="handleCancel">
    <span slot="title"
      ><a-icon :style="{ color }" type="info-circle" /><span style="margin-left:10px">请选择同步方式</span></span
    >
    <a-form :label-col="{ span: 10 }" :wrapper-col="{ span: 14 }">
      <!--       <a-form-item v-if="syncType == 'GLOBAL'" label="未匹配到时新建">
        <a-radio-group
          :options="[
            { label: '是', value: true },
            { label: '否', value: false }
          ]"
          v-model="isForce"
        />
      </a-form-item> -->
      <a-form-item v-if="syncType == 'GLOBAL'" label="同步平台">
        <!-- 陈立让改成左对齐 -->
        <a-tree-select
          v-model="platformIds"
          dropdownClassName="sync-tree"
          treeDefaultExpandAll
          style="width: 260px"
          :tree-data="treeData"
          allowClear
          @change="onChange"
          :maxTagCount="2"
          :replaceFields="{ children: 'childrenList', title: 'showName' }"
          tree-checkable
          placeholder="请选择平台"
        />
      </a-form-item>
      <a-form-item label="同步作品">
        <a-tree-select
          v-model="productIds"
          dropdownClassName="sync-tree"
          treeDefaultExpandAll
          style="width: 260px"
          :tree-data="productList"
          allowClear
          :maxTagCount="2"
          :replaceFields="{ children: 'childrenList', title: 'showName' }"
          tree-checkable
          placeholder="请选择作品"
        />
      </a-form-item>
      <a-form-item label="同步阶段" v-if="url != '/stage/sync'">
        <a-tree-select
          v-model="selectedStages"
          dropdownClassName="sync-tree"
          style="width: 260px"
          allowClear
          tree-checkable
          :tree-data="treeStageList"
          search-placeholder="选择阶段"
          placeholder="请选择阶段"
          @change="changeStageList"
        />
      </a-form-item>
      <a-form-item label="同步内容" v-if="url != '/stage/sync'">
        <a-checkbox-group v-model="checkedList" style="width:260px">
          <a-row>
            <a-col :span="12" style="position: relative;top: 5px;">
              <a-checkbox value="hasBaseInfo">
                基础信息
              </a-checkbox>
            </a-col>
            <a-col :span="12" style="position: relative;top: 5px;">
              <a-checkbox value="hasContent">
                正文
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { postAction, getAction } from '@/api/manage'
import { getCurUserDepartTree } from '@/api/productTree'
export default {
  props: {
    syncType: {
      type: String,
      default: 'GLOBAL'
    },
    url: {
      type: String,
      default: ''
    },
    selectedRowKeys: {
      type: Array,
      default: () => []
    },
    selectionRows: {
      type: Array,
      default: () => []
    },
    idType: {
      type: String,
      default: 'ids'
    }
  },
  data() {
    return {
      visible: false,
      color: '',
      platformIds: [],
      productIds: [],
      productList: [],
      isForce: true,
      checkedList: ['hasBaseInfo', 'hasContent'],
      treeData: [],
      spinning: false,
      productName: '',
      selectedStages: [],
      stageList: [],
      treeStageList: []
    }
  },
  created() {},
  mounted() {
    this.color = this.$store.state.app.color
  },
  methods: {
    changeStageList() {
      console.log(this.selectedStages)
    },
    getStageList() {
      getAction('/stage/list?pageSize=100').then(({ success, data }) => {
        this.stageList = data
        this.getTreeStageList()
      })
    },
    async onChange() {
      if (!this.platformIds.length) return
      const res = await postAction('/production/query', this.platformIds)
      this.productList = []
      res.data.forEach(item => {
        if (this.platformIds.some(p => p == item.platformId)) {
          if (this.productList.every(i => i.value != item.platformId)) {
            this.productList.push({
              key: item.platformId,
              showName: item.platformName || '',
              isPlatform: true,
              value: item.platformId,
              childrenList: []
            })
          }
        }
      })
      this.productList.forEach(item => {
        res.data.forEach(i => {
          if (item.value == i.platformId) {
            item.childrenList.push({ showName: i.productionName, key: i.productionId, value: i.productionId })
          }
        })
      })
      let arr = JSON.parse(JSON.stringify(this.productList))
      this.productList = [
        {
          key: '所有作品',
          showName: '所有作品',
          value: '所有作品',
          childrenList: arr
        }
      ]
    },
    getTreeData(params = {}) {
      if (this.spinning) {
        return
      }
      this.spinning = true
      let nowParams = {
        productName: this.productName || '',
        platformIds: [],
        coordinatorIds: [],
        ...params
      }
      getCurUserDepartTree(nowParams).then(res => {
        let list = res.data.filter(item => item.platformList && item.platformList.length) || []
        list.forEach((item, index) => {
          item.isDepartment = true
          item.index = index
          item.key = item.id
          item.checked = false
          item.showName = item.parentName || ''
          item.value = item.id
          item.childrenList = item.platformList?.map((child, c_index) => {
            child.isPlatform = true
            child.checked = false
            child.index = c_index
            child.key = `platform_${child.platformId}`
            child.value = child.platformId
            child.hasChild = child.productTotal > 0
            child.showName = child.platformName || ''
            child.showCount = child.productTotal || 0
            child.childrenList = []
            return child
          })
          item.platformList = undefined
        })
        if (this.selectionRows[0].departments && this.selectionRows[0].departments.length) {
          this.treeData = [
            {
              isDepartment: true,
              index: 1000,
              key: 1000,
              value: 1000,
              checked: false,
              showName: '所有平台',
              childrenList: list.filter(i => this.selectionRows[0].departments.some(item => item.departId == i.id))
            }
          ]
        } else {
          this.treeData = [
            {
              isDepartment: true,
              index: 1000,
              key: 1000,
              value: 1000,
              checked: false,
              showName: '所有平台',
              childrenList: list
            }
          ]
        }
        this.spinning = false
      })
    },
    open() {
      this.visible = true
      this.getTreeData()
      this.getStageList()
    },
    getTreeStageList() {
      console.log({ 'this.stageList': this.stageList })
      let treeStageList = this.stageList
        .filter(i => i)
        .map(({ id: key, stageId: value, stageName: title }) => ({ title, key, value }))
      this.treeStageList = treeStageList
    },
    async handleOk() {
      if (!this.url) return
      let params = {
        platformIds: this.platformIds,
        /* isForce: this.isForce, */
        hasBaseInfo: this.checkedList.some(item => item == 'hasBaseInfo'),
        hasContent: this.checkedList.some(item => item == 'hasContent'),
        productIds: this.productIds,
        stageIds: this.selectedStages
      }
      params[this.idType] = this.selectedRowKeys
      const res = await postAction(this.url, params)
      if (res.code == 200) {
        this.$message.success('同步成功')
        this.selectedStages = []
        this.productIds = []
        this.checkedList = ['hasBaseInfo', 'hasContent']
        this.platformIds = []
        this.$emit('loadData')
      } else {
        this.$message.error(res.message || res.msg)
      }
      this.visible = false
    },
    handleCancel() {
      this.visible = false
      this.selectedStages = []
      this.productIds = []
      this.checkedList = ['hasBaseInfo', 'hasContent']
      this.platformIds = []
    }
  }
}
</script>

<style lang="less" scoped></style>
