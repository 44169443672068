var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: { width: "520px", visible: _vm.visible },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "span",
        { attrs: { slot: "title" }, slot: "title" },
        [
          _c("a-icon", {
            style: { color: _vm.color },
            attrs: { type: "info-circle" },
          }),
          _c("span", { staticStyle: { "margin-left": "10px" } }, [
            _vm._v("请选择同步方式"),
          ]),
        ],
        1
      ),
      _c(
        "a-form",
        { attrs: { "label-col": { span: 10 }, "wrapper-col": { span: 14 } } },
        [
          _vm.syncType == "GLOBAL"
            ? _c(
                "a-form-item",
                { attrs: { label: "同步平台" } },
                [
                  _c("a-tree-select", {
                    staticStyle: { width: "260px" },
                    attrs: {
                      dropdownClassName: "sync-tree",
                      treeDefaultExpandAll: "",
                      "tree-data": _vm.treeData,
                      allowClear: "",
                      maxTagCount: 2,
                      replaceFields: {
                        children: "childrenList",
                        title: "showName",
                      },
                      "tree-checkable": "",
                      placeholder: "请选择平台",
                    },
                    on: { change: _vm.onChange },
                    model: {
                      value: _vm.platformIds,
                      callback: function ($$v) {
                        _vm.platformIds = $$v
                      },
                      expression: "platformIds",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-item",
            { attrs: { label: "同步作品" } },
            [
              _c("a-tree-select", {
                staticStyle: { width: "260px" },
                attrs: {
                  dropdownClassName: "sync-tree",
                  treeDefaultExpandAll: "",
                  "tree-data": _vm.productList,
                  allowClear: "",
                  maxTagCount: 2,
                  replaceFields: {
                    children: "childrenList",
                    title: "showName",
                  },
                  "tree-checkable": "",
                  placeholder: "请选择作品",
                },
                model: {
                  value: _vm.productIds,
                  callback: function ($$v) {
                    _vm.productIds = $$v
                  },
                  expression: "productIds",
                },
              }),
            ],
            1
          ),
          _vm.url != "/stage/sync"
            ? _c(
                "a-form-item",
                { attrs: { label: "同步阶段" } },
                [
                  _c("a-tree-select", {
                    staticStyle: { width: "260px" },
                    attrs: {
                      dropdownClassName: "sync-tree",
                      allowClear: "",
                      "tree-checkable": "",
                      "tree-data": _vm.treeStageList,
                      "search-placeholder": "选择阶段",
                      placeholder: "请选择阶段",
                    },
                    on: { change: _vm.changeStageList },
                    model: {
                      value: _vm.selectedStages,
                      callback: function ($$v) {
                        _vm.selectedStages = $$v
                      },
                      expression: "selectedStages",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.url != "/stage/sync"
            ? _c(
                "a-form-item",
                { attrs: { label: "同步内容" } },
                [
                  _c(
                    "a-checkbox-group",
                    {
                      staticStyle: { width: "260px" },
                      model: {
                        value: _vm.checkedList,
                        callback: function ($$v) {
                          _vm.checkedList = $$v
                        },
                        expression: "checkedList",
                      },
                    },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            {
                              staticStyle: { position: "relative", top: "5px" },
                              attrs: { span: 12 },
                            },
                            [
                              _c(
                                "a-checkbox",
                                { attrs: { value: "hasBaseInfo" } },
                                [
                                  _vm._v(
                                    "\n              基础信息\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              staticStyle: { position: "relative", top: "5px" },
                              attrs: { span: 12 },
                            },
                            [
                              _c(
                                "a-checkbox",
                                { attrs: { value: "hasContent" } },
                                [_vm._v("\n              正文\n            ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }